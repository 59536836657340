
import { Component, Vue } from "vue-property-decorator";

import Thumbnail from "@/components/Thumbnail.vue";
import FeaturedThumbnail from "@/components/FeaturedThumbnail.vue";
import ClassCarousel from '@/components/ClassCarousel.vue';
import BlogCard from "@/components/BlogCard.vue";
import BlogCardFull from "@/components/BlogCardFull.vue";
import { Blog } from "@/store/modules";
import { clone } from "ramda";
import moment from "moment";
import router from "@/router";


@Component({
    name: "Categories",
    components: {
        Thumbnail,
        FeaturedThumbnail,
        ClassCarousel,
        BlogCard,
        BlogCardFull
    },
})
export default class Home extends Vue {
    itemsPerPage = 9;
    page = 0;
    pages = 12;
    currentPage = 1;

    postData: any = {
        id: '',
        image: '',
        title: '',
        description: '',
        category: '',
        date: '',
        author: ''
    }

    postRecent: any = {
        id: '',
        image: '',
        title: '',
        description: '',
        category: '',
        date: '',
        author: ''
    }

    async getPosts() {
        const item = await Blog.getBlogPosts();
        return item
    }

    async mounted() {
        const response = await this.getPosts()
        const cloneRes = clone(response)

        this.pages = Math.ceil(cloneRes.length / this.itemsPerPage)


        this.postData = cloneRes.slice(0, this.itemsPerPage)

        this.postRecent = cloneRes[cloneRes.length - 1]
    }

    async getPostsPagination() {
        const response = await this.getPosts()
        const cloneRes = clone(response)

        if (this.currentPage > 0) {
            const offset = (this.currentPage - 1) * this.itemsPerPage
            this.postData = cloneRes.slice(offset, this.itemsPerPage * this.currentPage)

            return;
        } else {
            this.postData = cloneRes.slice(0, this.itemsPerPage)
        }

    }

    getFormattedDate(date: any) {
        return moment(date).format("MMM Do, YYYY")
    }

    clickHomeRouter() {
        router.push({ name: 'home' });
    }

    async pageChanged() {
        await this.getPostsPagination();
    }
}
