
import router from '@/router';
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({
    name: 'BlogCardFull',
})
export default class BlogCardFull extends Vue {
    @Prop() author!: string;
    @Prop() title!: string;
    @Prop() date!: string;
    @Prop() category!: string;
    @Prop() description!: string;
    @Prop() img!: string;
    @Prop() id!: string;


    clickPost(slug: string) {
        router.push({ name: 'post', params: { slug: slug } })
    }
}
